(function (root, factory) {
	if (typeof define === 'function' && define.amd) {
		// AMD. Register as an anonymous module.
		define([], function () {
			return (root.returnExportsGlobal = factory());
		});
	} else if (typeof exports === 'object') {
		// Node. Does not work with strict CommonJS, but
		// only CommonJS-like enviroments that support module.exports,
		// like Node.
		module.exports = factory();
	} else {
		root['Chartist.plugins.ctThreshold'] = factory();
	}
})(this, function () {
	/**
	 * Chartist.js plugin to display a data label on top of the points in a line chart.
	 *
	 */
	/* global Chartist */
	(function (window, document, Chartist) {
		'use strict';

		var thresholdTooltip;
		var startupThresholdTooltipShown;
		var resizeWatcher;
		var thresholdTooltipPublic = {};

		var defaultOptions = {
			threshold: 0,
			classNames: {
				aboveThreshold: 'ct-threshold-above',
				belowThreshold: 'ct-threshold-below',
			},
			maskNames: {
				aboveThreshold: 'ct-threshold-mask-above',
				belowThreshold: 'ct-threshold-mask-below',
			},
		};

		function createMasks(data, options) {
			// Select the defs element within the chart or create a new one
			var defs = data.svg.querySelector('defs') || data.svg.elem('defs');
			// Project the threshold value on the chart Y axis
			var projectedThreshold =
				data.chartRect.height() -
				data.axisY.projectValue(options.threshold) +
				data.chartRect.y2;
			var width = data.svg.width();
			var height = data.svg.height();

			//Create a line for the threshold
			var chartSVG = data.svg;
			chartSVG.elem('line', {
				x1: 0,
				x2: width,
				y1: projectedThreshold,
				y2: projectedThreshold,
				id: 'threshold-line',
				class: 'threshold-line',
				style: 'stroke: ' + options.thresholdLineColor,
			});

			addThresholdTag(data, options);

			// Create mask for upper part above threshold
			defs.elem('mask', {
				x: 0,
				y: 0,
				width: width,
				height: height,
				id: options.maskNames.aboveThreshold,
			}).elem('rect', {
				x: 0,
				y: 0,
				width: width,
				height: projectedThreshold,
				fill: 'white',
			});

			// Create mask for lower part below threshold
			defs.elem('mask', {
				x: 0,
				y: 0,
				width: width,
				height: height,
				id: options.maskNames.belowThreshold,
			}).elem('rect', {
				x: 0,
				y: projectedThreshold,
				width: width,
				height: height - projectedThreshold,
				fill: 'white',
			});

			return defs;
		}

		Chartist.thresholdTooltip = thresholdTooltipPublic;
		Chartist.plugins = Chartist.plugins || {};
		Chartist.plugins.ctThreshold = function (options) {
			options = Chartist.extend({}, defaultOptions, options);

			return function ctThreshold(chart) {
				var aboveThresholdElement;
				if (
					chart instanceof Chartist.Line ||
					chart instanceof Chartist.Bar
				) {
					chart.on('draw', function (data) {
						if (data.type === 'point') {
							// For points we can just use the data value and compare against the threshold in order to determine
							// the appropriate class
							data.element.addClass(
								data.value.y >= options.threshold
									? options.classNames.aboveThreshold
									: options.classNames.belowThreshold
							);
						} else if (
							data.type === 'lines' ||
							data.type === 'bars' ||
							data.type === 'area'
						) {
							// Cloning the original line path, mask it with the upper mask rect above the threshold and add the
							// class for above threshold
							aboveThresholdElement = data.element
								.parent()
								.elem(data.element._node.cloneNode(true))
								.attr({
									mask:
										'url(#' +
										options.maskNames.aboveThreshold +
										')',
								})
								.addClass(options.classNames.aboveThreshold);

							// Use the original line path, mask it with the lower mask rect below the threshold and add the class
							// for below threshold
							data.element
								.attr({
									mask:
										'url(#' +
										options.maskNames.belowThreshold +
										')',
								})
								.addClass(options.classNames.belowThreshold);
						}

						if (data.type === 'area') {
							aboveThresholdElement.attr({
								style:
									'fill: ' +
									options.thresholdFillColor +
									'; ' +
									'fill-opacity: ' +
									options.thresholdFillOpacity +
									';',
							});
						}
					});

					// On the created event, create the two mask definitions used to mask the line graphs
					chart.on('created', function (data) {
						createMasks(data, options);
					});
				}
			};
		};

		function addThresholdTag(data, options) {
			// Assign public methods
			thresholdTooltipPublic.destroy = destroy;

			var firstShownDuration = 4000;
			var firstShownDelay = 1000;

			var footerElement = document.querySelector(
				'.calendar-info .calendar-footer'
			);
			var thresholdElement = document.getElementById('threshold-line');
			var appendParentElement = document.querySelector('.calendar');
			var containerElement = document.createElement('div');
			var tooltipElement = document.createElement('div');
			var tooltipContentElement = document.createElement('div');
			var tooltipArrowElement = document.createElement('div');
			var lineElement = document.createElement('div');
			var threshold = Number(options.threshold);

			if (!appendParentElement || !thresholdElement) {
				return;
			}

			var footerHeight = footerElement.offsetHeight;
			var thresholdBounds = thresholdElement.getBoundingClientRect();
			var appendParentBounds =
				appendParentElement.getBoundingClientRect();
			data.axisY.axisLength / (data.bounds.valueRange / threshold) +
				footerHeight; // Add footer height
			var lineColor = options.thresholdLineColor;

			var tooltipHeightOffset = 4;
			var animationDirection;
			var startupAnimation;
			var tooltipShown;
			var tooltipHeight;
			var lastHeight;
			var resizeWatcherDebounce;

			containerElement.className = 'threshold-tooltip-container';
			tooltipElement.className =
				'threshold-tooltip tooltip-large tooltip-chart';
			tooltipContentElement.className = 'tooltip-inner';
			tooltipArrowElement.className = 'threshold-tooltip-arrow';

			var containerTop =
				appendParentBounds.height -
				data.axisY.axisLength -
				footerHeight -
				45;
			containerElement.style.top = containerTop + 'px';
			tooltipElement.style.opacity = 0;
			lineElement.style.borderColor = lineColor;
			lineElement.className = 'threshold-tooltip-line';

			tooltipContentElement.style.borderColor = lineColor;
			tooltipArrowElement.style.borderColor =
				lineColor + ' transparent transparent transparent';

			var value = '';
			value = '<div>' + options.thresholdDisplay + '</div>';
			tooltipContentElement.innerHTML = value;

			if (thresholdTooltip) {
				destroy();
			}

			tooltipElement.appendChild(tooltipContentElement);
			tooltipElement.appendChild(tooltipArrowElement);
			containerElement.appendChild(tooltipElement);
			containerElement.appendChild(lineElement);
			appendParentElement.appendChild(containerElement);
			thresholdTooltip = containerElement;

			tooltipHeight = tooltipElement.offsetHeight;

			lineElement.style.top = tooltipHeight - tooltipHeightOffset + 'px';

			lineElement.style.height =
				thresholdBounds.top -
				appendParentBounds.top -
				containerTop -
				tooltipHeight +
				'px';

			// Add event listener for mouse hover
			document.body.addEventListener('mousemove', hoverListener);

			// Add a resize observer to recreate tooltip when the parent is resized
			resizeWatcher = new ResizeObserver(function (observedItems) {
				var observedItem = observedItems[0];
				var itemHeight = observedItem.contentRect.height;
				if (lastHeight && lastHeight !== itemHeight) {
					if (resizeWatcherDebounce) {
						window.clearTimeout(resizeWatcherDebounce);
					}
					resizeWatcherDebounce = window.setTimeout(function () {
						destroy(function () {
							addThresholdTag(data, options);
						});
					}, 500);
				}
				lastHeight = itemHeight;
			});
			resizeWatcher.observe(appendParentElement);

			// Initial tooltip animation
			if (!startupThresholdTooltipShown) {
				startupAnimation = window.setTimeout(function () {
					showThresholdTooltip();
					startupThresholdTooltipShown = true;
					// Set new timeout for hiding the popover
					startupAnimation = window.setTimeout(function () {
						hideThresholdTooltip();
						startupAnimation = null;
					}, firstShownDuration);
				}, firstShownDelay);
			}

			function lineIn() {
				tooltipElement.removeEventListener('transitionend', lineIn);

				// Don't continue if the tooltip isn't shown
				if (!tooltipShown) {
					return;
				}
				lineElement.classList.remove('out');
				lineElement.classList.add('in');
				animationDirection = 1;
			}
			function lineOut() {
				lineElement.removeEventListener('transitionend', lineOut);

				// Don't continue if the tooltip is shown
				if (tooltipShown) {
					return;
				}
				tooltipElement.style.opacity = 0;
				animationDirection = 0;
			}

			function showThresholdTooltip() {
				tooltipShown = true;
				if (startupAnimation) {
					window.clearTimeout(startupAnimation);
					startupAnimation = null;
				}
				if (!animationDirection) {
					tooltipElement.addEventListener('transitionend', lineIn);
				} else {
					lineIn();
				}
				tooltipElement.style.opacity = 1;
			}

			function hideThresholdTooltip() {
				tooltipShown = false;
				if (startupAnimation) {
					window.clearTimeout(startupAnimation);
					startupAnimation = null;
				}
				if (animationDirection) {
					lineElement.addEventListener('transitionend', lineOut);
				} else {
					lineOut();
				}
				lineElement.classList.remove('in');
				lineElement.classList.add('out');
			}

			function hoverListener(e) {
				var isHovered = hoverDetected(e);
				if (
					(!tooltipShown && isHovered) ||
					(startupAnimation && isHovered)
				) {
					thresholdElement.style.strokeWidth = '6px';

					showThresholdTooltip();
				} else if (!startupAnimation && tooltipShown && !isHovered) {
					thresholdElement.style.strokeWidth = '4px';
					hideThresholdTooltip();
				}
			}

			function hoverDetected(e) {
				var elementPad = 6;
				// Uses vertical postion of line and horizontal borders of container
				// because the line may extend past the borders and not be visible
				return (
					e.clientY >= thresholdBounds.bottom - elementPad &&
					e.clientY <= thresholdBounds.top + elementPad &&
					e.clientX >= appendParentBounds.left &&
					e.clientX <= appendParentBounds.right
				);
			}

			function destroy(callback) {
				// Only destroy if the tooltip element still exists
				if (!thresholdTooltip) {
					return;
				}
				// Remove hover listener
				document.body.removeEventListener('mouseover', hoverListener);
				// Destroy element
				thresholdTooltip.remove();
				// Remove the resize watcher
				resizeWatcher.unobserve(appendParentElement);
				// Destroy the cached tooltip object variable
				thresholdTooltip = null;

				if (callback) {
					callback();
				}
			}
		}
	})(window, document, Chartist);

	return Chartist.plugins.ctThreshold;
});
